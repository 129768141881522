import React, { useRef } from "react";
import Page from "../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"
import investingfonds from "../images/investingfonds.png";
import borsdata from "../images/borsdata.jpg";
import { graphql } from "gatsby";

export default function Sparande({ data }) {
  const buffert = useRef(null);
  const sparguide = useRef(null);
  const aktieguiden = useRef(null);

  const image = data.image.childImageSharp.gatsbyImageData;
  const title = "Tjäna pengar på börsen | Börja spara och investera"

  return (
    <>
      <Page title={title} description="En guide för att du skall lära dig mer om ekonomi och sparande. Lär dig om varför du bör ha en buffert och om aktier, samt påbörja din egna sparresa.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="spargris" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>Den ultimata guiden för att påbörja din sparresa.
            </p>
            <h2>1. Bygg en buffert</h2>
            <p>
              Vad är en buffert och hur mycket pengar behöver jag till en? En
              buffert är en summa pengar som endast skall användas till
              <b> oförutsedda utgifter.</b> Det kan exempelvis handla om att
              tvättmaskinen går sönder, eller kanske en större oförutsedd
              händelse om att du förlorar ditt jobb. En buffert är alltså ett
              trygghetsnät som du alltid kan luta dig tillbaka på om något
              oförutsedd utgift skulle inträffa.
            </p>
            <h3>Hur stor buffert bör du ha?</h3>
            <p>
              Storleken på din buffert kan vara mycket inviduduellt, och beror
              på flera faktorer såsom hur stora dina utgifter är vanligtvis,
              storleken på din familj och hur mycket du själv känner att du
              behöver för att du skall känna dig trygg. För i grunden handlar
              allt om trygghet.
            </p>
            <p>
              Oftast pratar man om att du bör ha en buffert på omkring <b>två till
                tre månadslöner</b>, men buffert kan också vara högre och lägre
              beroende på din situation. Om du exempelvis har ett stort hushåll
              och kanske har bil, husdjur och ett fritidshus kan en större
              buffert vara att rekommendera. Medan om du exempelvis bor själv i
              en studentlägenhet och har få möjliga oförutsedda händelser kommer
              du inte behöva en lika stor buffert. Men överlag handlar bufferten
              som sagt för att få dig att känna dig trygg och så att du skall
              känna att du kan sova gott om natten.
            </p>
            <h3>Hur bygger man upp en buffert?</h3>
            <p>
              Om du inte har en buffert i nuläget rekommenderar jag att du
              sätter igång ett månadssparande, där du varje månad tar bort lite
              pengar från ditt lönekonto och sätter över till ditt sparkonto.
              Sätt gärna upp så att månadssparandet sker automatiskt, exempelvis
              direkt när du får lönen så du ser till att sparandet blir gjort.
              Att spara 10% av sin lön brukar vara en rekommendation eftersom
              det är något som de flesta brukar klarar av. Men om du känner att
              du också kan spara mer än det är ju det självklart en
              rekommendation. Det är viktigt att försöka bygga upp en buffert så
              att du känner att du klarar alla oförutsedda kostnader.
            </p>
            <h3>Var skall bufferten placeras?</h3>
            <p>
              Tanken med en buffert var som tidigare sagt att du snabbt skall
              kunna komma åt den om du skulle behöva den. Därför bör den också
              placeras i ett tryggt alternativ där du inte riskerar att förlora
              pengarna och så att du snabbt också kan komma åt dem. Därmed
              passar det därför inte att du exempelvis placeras dina
              buffertpengar i aktier eller fonder. De bör i stället placeras på
              ett sparkonto där du har insättningsgaranti. Du kan exempelvis
              använda ett sparkonto på hos egna bank, eller sätta upp ett på
              exempelvis Avanza där du kan få upp till 0,60% ränta i nuläget via
              exempelvis Collector.
            </p>
            <hr ref={sparguide} />
            <h2>2. Påbörja din sparresa</h2>
            <p>
              Det bästa tillfället att börja var för tjugo år sedan, det näst
              bästa tillfället är idag. Det är alldrig försent för att börja
              spara och investera för att skapa en bättre morgondag för dig
              själv. Nedan har jag delat med mig av liten guide för att du
              enklare ska kunna komma igång.
            </p>
            <h3>Bestäm dig hur länge du vill spara</h3>
            <p>
              Detta är viktigt för att avgöra hur stor risk du bör ta. Om du
              investerar pengarna på börsen kan värdet både gå och upp ner på
              kort sikt. På längre sikt minskar dock risken eftersom börsen
              övertid brukar gå upp. Dessa tumregler kan du ha med dig när du
              bestämmer dig:
            </p>
            <div>
              <ul>
                <li>
                   Pengarna ska användas inom två år – sparkonto (ingen risk)
                </li>
                <li>
                   Pengarna ska användas inom fem-tio år – börsen med
                  lägre-medelhög risk
                </li>
                <li>
                   Pengarna ska användas om mer än tio år – börsen med hög risk
                </li>
              </ul>
            </div>
            <h3>Hur mycket är du villig att spara?</h3>
            <p>
              Hur mycket är du villig att spara av din lön? Man brukar säga att
              10% är ett bra riktmärke eftersom det är något som de flesta
              klarar av. Men jag skulle också säga att desto mer du kan spara
              desto bättre. Eftersom desto mer du sparar, desto mer kan du
              utnyttja ränta- på ränta effekten.
            </p>
            <h3>Börja enkelt</h3>
            <p>
              Man måste komma ihåg att jag inte är någon finansiell rådgivare,
              så jag kan inte själv ge råd till vad man bör köpa och inte köpa.
              Men jag kan däremot säga vad jag skulle gjort om jag var helt nu
              till sparande, så hade jag huvudsakligen valt att investera i
              indexfonder vilket är passiv men väldigt bekväm strategi. För att
              även förklara vad en indexfond är så är det en värdepappersfond
              som strävar efter att dess värdeutveckling ska spegla ett visst
              marknadsindex. Detta kan vara exempelvis den globala ekonomin
              eller den svenska börsen. Fördelen med en indexfond är dessutom
              att förvaltningsavgiften är väldigt låg.
            </p>
            <h3>Första steget</h3>
            <p>
              Om jag hade startat idag hade jag valt att starta ett
              månadssparande eftersom det är ett mycket enkelt sätt att spara i
              eftersom det sker automatiskt. Avanza har dessutom en mycket bra
              fondlista där man själv kan hitta och välja fonder man tycker
              låter intressanta. Låt säga att vi skulle ha 1000kr då hade ett
              månadssparande kunnat se ut såhär.
            </p>
            <img
              src={investingfonds}
              className="investingFondsImg"
              loading="lazy"
              alt="fonder sparande"
            />
            <h3>Sätt igång</h3>
            <p>
              Jag hoppas att du uppskattat läsningen! Nu är det bara att sätta
              igång. Jag själv rekommenderar{" "}
              <a
                href="https://www.avanza.se/start"
                rel="noopener noreferrer"
                target="_blank"
              >
                Avanza
              </a>{" "}
              som nätmäklare. Det är nätmäklaren som jag själv använder mig av.
            </p>
            <hr ref={aktieguiden} />
            <h2>3. Att spara i aktier</h2>
            <p>
              Om du har kommit såhär långt har du förhoppningvis en hyfsad bra
              koll på ditt sparande. Förmodligen har du en buffert sedan
              tidigare och förmodligen har du också ett fondsparande. I detta
              avsnittet kommer vi nu gräva ner oss lite mer i aktier, vad är det
              och vad bör man tänka på?
            </p>
            <h3>Vad är en aktie?</h3>
            <p>
              En aktie är en andel i ett aktiebolag (AB), som motsvarar en
              kapitalinsats från ägaren, och som bildar bolagets aktiekapital.
              En aktie är i grunden en rätt till bolagets tillgångar och
              resultat sedan bolagets fordringsägare fått sitt. Genom att äga en
              aktie för rösträtt på aktiebolagets bolagsstämma, samt rätten till
              utdelning, vilket är när företaget delar ut en del av sin vinst
              till aktieägarna.
            </p>
            <p>
              Aktier utges även i olika typer med varierande vilkor, och om du
              har kollat på aktier tidigare så kanske du har märkt detta.
              Exempelvis finns det både Investor A och Investor B. Det som
              skiljer dessa åt är i regel rösträtten, där A-aktierna har en
              rösträtt och B-aktierna 1/10-rösträtt. Spelar detta någon roll för
              dig? Nej, det gör det inte. Anledning till att det ser ut så här
              är för att de ursprungliga aktieägarna vill behålla makten i
              företaget, men ändå ha möjligheten att ta in mer riskkapital.
              Därav spelar det mindre roll för dig om du väljer att äga A- eller
              B-aktien. Något som däremot kan vara bra att tänka på är att
              omsättningen av A-aktierna kan vara mycket lägre vilket kan göra
              det svårare att sälja dem ibland via marknaden, därför kan
              B-aktien ibland vara att rekommendera.
            </p>
            <h3>Nyckeltal för att värdera en aktie</h3>
            <p>
              När man värderar värdet för en aktie finns det många olika
              nyckeltal som man kan kolla på. Fem viktig nyckeltal
              är P/E, P/S, P/B-talet, direktavkastning och soliditet och de går vi igenom nedan.
            </p>
            <h4>P/E-talet</h4>
            <p>
              P/E står för Price per Earning och räknas ut genom att man
              dividerar den nuvarande aktiekursen med vinsten per aktie. Det som
              detta nycketalet säger är att säg till exempel att vi har ett
              bolag med P/E 14. Då köper vi bolaget till 14 gånger årsvinsten.
              P/E-talet är att bra nyckeltal att börja titta på vid start för
              att få en bred överblick hur bolaget ser ut och kan exempelvis
              användas för att jämföras med andra bolag i samma bransch. I regel
              brukar man säga att ett bolag under 14 brukar vara billigt, men
              var noga med att alltid fråga dig själv varför ett bolag värderas
              till ett visst pris, det kan vara många orsaker bakom. Snittet de
              senaste 100 åren på Stockholmsbörsen ligger på runt också runt 14.
            </p>
            <p>
              Om ett P/E-tal är högt kan det visa på att det finns höga
              förväntningar på att bolaget skall växa, och därav är även
              marknaden beredd på att betala mer för företaget. Dessutom om ett
              P/E-tal skulle vara negativt säger detta ingen om förväntningarna
              utan de visar att bolaget går med förlust eftersom vinsten (E) är
              negativ.
            </p>
            <h4>P/S-talet</h4>
            <p>
              P/S står för Price per Sales och räknas ut genom att man dividerar
              den nuvarande aktiekursen med omsättningen per aktie under ett
              helt år. P/S-talet visar hur högt marknanden värderar bolagets
              omsättning. Ett lågt P/S-tal kan indikera flera saker, det kan
              indiktera att aktien är lågt värderad. Men det kan också indiktera
              att marknaden tror att omsättningen kommer minska eller att
              bolaget har stora skulder. P/S-talet och P/E-talet är dessutom bra
              att använda tillsammans när man exempelvis analyserar
              tillväxtbolag.
            </p>
            <h4>P/B-talet</h4>
            <p>
              P/B står för Price per Bookvalue och räknas ut genom att man
              dividerar den nuvarande aktiekursen med bolagets egna kapital per
              aktie. P/B-talet ger oss alltså information om hur mycket eget
              kapital bolaget har i relation till den nuvarande aktiekursen.
              Detta nyckeltalet är speciellt bra att använda sig av när man
              analyseras bolag inom en kapitaltung bransch, såsom skogbolag
              eller fastighetsbolag. Om ett P/B-tal är under 1 kan det indiktera
              flera saker. Antingen är bolaget undervärderat, eller så kan
              bolaget har övervärderade tillgångar. Men det kan också indiktera
              att bolaget har en negativ avkastning på sina tillgångar. Så kom
              ihåg att alltid göra en närmare analys innan du väljer att
              investera i något bolaget.
            </p>
            <h4>Direktavkastning</h4>
            <p>
              Direktavkastning beräknas genom att man dividerar historisk eller
              förväntad utdelning per aktie med aktiepriset. Direktavkastningen
              visar därmed hur stor procentuell del av din investering du får
              tillbaka i form av utdelning varje år. Alltså, desto högre
              direktavkastning ett företag har, desto mer utdelning per
              investerad får du. Detta är självklart positivt men det är viktigt
              att vara försiktig om direktavkastningen skulle vara orimligt hög.
              Om den skulle vara orimligt hög kan det bero på företaget delar ut
              en extrautdelning eller att aktien nyligen fallit mycket.
            </p>
            <h4>Soliditet</h4>
            <p>
              Soliditet visar hur stor andel av tillgångarna som är finansierade
              med eget kapital. Soliditeten brukar normalt vara mellan 30-40
              procent. En anledning till varför företagen annars tar lån är att
              det ger bättre möjligheter till högre avkastning på det egna
              kapitalet än att finansiera hela sin verksamhet med eget kapital.
              Om företaget skulle ha låg soliditet kan det betyda att företaget
              har för mycket belåning och det kan få väldigt negativa effekter.
            </p>
            <p>
              Ett bra ställe att hitta dessa nyckeltal och annan finansiell
              information är{" "}
              <a
                href="https://borsdata.se/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Börsdata
              </a>
              . På deras sajt kan du enkelt hitta allt information kring olika bolag och nedan ser du en skärmavbild från deras hemsida.
            </p>
            <img
              src={borsdata}
              className="imageFullWidth"
              alt="börsdata nyckeltal"
              loading="lazy"
            />
            <h3>
              Skall man spara i ett aktie- och fondkonto, ISK eller en KF?
            </h3>
            <p>
              Det finns flera olika typer av konton som man kan äga sin aktier
              i. Dessa är aktie- och fondkonto, investeringssparkonto och
              kapitalförsäkring. Det är viktigt att veta skillanden mellan dessa
              eftersom de beskattas på olika sätt och har olika förmåner.
            </p>
            <p>
              I regel däremot så brukar ett investeringssparkonto (ISK) eller
              kapitalförsäkring vara det mest gynnsamma om du tror att din
              avkastning kommer att bli högre än omkring 2%. Det är på grund av
              att skatten på ISK och kapitalförsäkring är 30 % av
              statslåneräntan (året innan), medan på en vanligt aktie- och
              fondkonto betalas 30 % kapitalvinstskatt på eventuella vinster. En
              annan fördel med en ISK eller KF är dessutom att du inte behöver
              deklarera dina aktiesaffärer eftersom du i stället betalar en
              schablonskatt. Det är din bank som du äger dina värdepapper hos
              som rapporterar in ditt skatteunderlag, så du behöver inte göra
              någoting själv.
            </p>
            <h3>Sprid riskerna</h3>
            <p>
              När man investerar i enskilda aktier är det också viktigt att man
              tänker på att sprida riskerna genom diversifiering. Man skall inte
              lägga alla ägg i samma korg som man brukar säga, och det samma
              gäller med aktier. Det finns flera olika sätt som man kan sprida
              och minimera risken i sin aktieportfölj. För det första så kan man
              välja att investera i flera olika bolag i olika sektorer såsom
              hälsovård, finans, teknik och skog m.m. Sedan kan också sprida och
              minimera risken på andra så såsom att investera på andra marknader
              såsom i USA eller i övriga Europa, samt så kan du diversiera över
              tiden och detta gör du genom att du sprider ut dina köp så att du
              inte går in med allt kapital på en gång. Regelbundna köp kan ha
              flera fördelar eftersom du kommer köpa i både uppgångar som
              nedgångar som i sin tur ger en jämnare utveckling i portföljen.
              Dessutom så kommer du att slippa huvudvärken att försöka tajma
              marknaden.
            </p>
            <p>
              Obs! Innehållet på sidan skall ej betraktas som finansiell
              rådgivning. Inget ansvar tas för dina investeringar eller för
              innehållet hos länkade tredjepartswebbsidor. En investering i
              värdepapper/fonder innebär alltid en risk och kan både öka och
              minska i värde och det är inte säkert att du får tillbaka det
              investerade kapitalet.
            </p>
            <br />
            <div>Reklam från Avanza</div>
            <a href="https://click.adrecord.com/?c=44861&p=836&g=25649">
              <img
                src="https://graphics.adrecord.com/44861/836/25649"
                className="avanzaImage"
                alt=""
                loading="lazy"
              />
            </a>
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query savingImage {
    image: file(relativePath: { eq: "savingImage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
